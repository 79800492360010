import React from 'react'
import Layout from '../../components/Layout'
import {Link} from "gatsby";

export default () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content has-text-centered">
          <h1>Thank you!</h1>
          <p>Your message has been sent and we will endeavour to get back to you as soon as possible.</p>
            <Link to={'/'}><btn className="button btn-primary">Back to Home</btn></Link>
        </div>
      </div>
    </section>
  </Layout>
)
